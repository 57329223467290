import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
function EnterStock({ show, handleClose, idProd }) {
  const [form, setForm] = useState({
    prodQte: "",
    outChecked: false,
    inChecked: false,
    prodBody: "",
  });
  //
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  //

  const handleSave = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("qte", form.prodQte);
    data.append("outChecked", form.outChecked);
    data.append("inChecked", form.inChecked);
    data.append("prodBody", form.prodBody);
    console.log(data);
  };

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSave}>
          <Modal.Header>
            <Modal.Title>Entrée Stock produit #{idProd}</Modal.Title>
            <Button variant="light" onClick={handleClose}>
              <i class="fas fa-times text-danger"></i>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="number"
              placeholder="Quantité de produit"
              className="mb-3"
              required
              onChange={(event) => handleChange("prodQte", event.target.value)}
            />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                placeholder="Observation"
                rows={3}
                required
                onChange={(event) =>
                  handleChange("prodBody", event.target.value)
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="success" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default EnterStock;
