import React, { Fragment } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrderList from "./OrderList";
function Order(props) {
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Commandes</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Tableau de Bord</a>
                </li>
                <li className="breadcrumb-item active">Commandes</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card">
        <div className="card-header">
          <div className="searchInput">
            <input
              className="form-control"
              disabled
              type="search"
              name=""
              id=""
              placeholder="Rechercher"
            />
          </div>

          <div className="card-tools">
            <Button variant="light" disabled>
              <i class="fas fa-file-excel"></i> Exporter
            </Button>
          </div>
        </div>
        <div className="card-body p-0">
          <div class="table-responsive scrollbar mx-n1 px-1">
            <Table className="fs--1 mb-0 table-smx table-hover">
              <thead>
                <tr>
                  <th>N°</th>
                  <th>MONTANT</th>
                  <th>VENDEUR</th>
                  <th>TELEPHONE</th>
                  <th>STATUT</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                <OrderList />
              </tbody>
            </Table>
          </div>
        </div>
        <div className="card-footer">{/* Footer */}</div>
      </div>
    </Fragment>
  );
}

export default Order;
