import React, { Fragment } from "react";

function Footer(props) {
  return (
    <Fragment>
      <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
          {/* <b>Version</b> 3.2.0 */}
        </div>
        <strong>
          Copyright &copy; 2023{" "}
          <a href="https://e-triangle.com" className="text-danger">
            E-Triangle.com
          </a>
          .
        </strong>{" "}
        All rights reserved.
      </footer>
    </Fragment>
  );
}

export default Footer;
