import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import dateFormat, { masks } from "dateformat";
import { i18n } from "dateformat";
import { Badge, Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import api from "../../api";
import { Link } from "react-router-dom";
function OrderList(props) {
  const [order, sertOrder] = useState([]);

  const fetchOrder = () => {
    api.get(`Orders/`).then((res) => {
      const order = res.data.result.orders;
      console.log(order);
      sertOrder(order);
    });
  };

  //
  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <Fragment>
      {order?.map((cmde) => (
        <tr>
          <td>
            <Link
              to={
                "/view-order?IdOrders=" +
                cmde.id +
                "&" +
                "IdSeller=" +
                cmde.seller.id
              }
            >
              #{cmde.id}
            </Link>
          </td>
          <td>{cmde.ttc_amount}FCFA</td>
          <td>{cmde.seller.first_name}</td>
          <td>{cmde.seller.phone_no}</td>
          <td>
            <Badge bg={cmde.statut_order.color}><span className="text-lowercase">{cmde.statut_order.title} </span>
            </Badge>
          </td>
          <td>{dateFormat(cmde.created, "ddd mmm dd yyyy HH:MM:ss")}</td>
        </tr>
      ))}
    </Fragment>
  );
}

export default OrderList;
