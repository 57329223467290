import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import api from "../../api";
import Swal from "sweetalert2";

function DeleteProduct({ onClose, show, prodId, reFresh }) {
  const handleDelete = () => {
    api.delete(`Products/delete/${prodId}`).then((res) => {
      if (res.data.result.feedback === "success") {
        Swal.fire("Supprimé!", "produit supprimé avec succès.", "success");
        reFresh();
        if (onClose) onClose();
      }
    });
  };
  return (
    <Fragment>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression produit #{prodId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <b className="font-weight-bold text-center">
            {" "}
            <i class="fas fa-exclamation-triangle text-danger"></i> La
            suppression est irrevesible!
          </b>
          <br />
          Etes-vous sur de vouloir vraiment supprimer ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Oui,Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default DeleteProduct;
