import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import api from "../../api";

function SubCatSelectBox({ idCategory }) {
  const [subCategorie, setSubCategorie] = useState([]);

  const fetchSubCategorie = () => {
    api
      .get(`SubCategories/getSubCategorieByCatId/` + idCategory)
      .then((res) => {
        const subCategories = res.data.result.subCategories;
        console.log(subCategories);
        setSubCategorie(subCategories);
      });
  };

  useEffect(() => {
    if (idCategory) {
      //alert(idCategory);
      fetchSubCategorie();
    }
  }, [idCategory]);
  return (
    <Fragment>
      <option selected disabled value="">
        Selectionnez...
      </option>
      {subCategorie.map((subCat, id) => (
        <option value={subCat.id} key={id}>
          {subCat.sub_cat_title}
        </option>
      ))}
    </Fragment>
  );
}

export default SubCatSelectBox;
