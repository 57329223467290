import React, { useEffect } from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import api from "../../api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState } from "react";

function EditSeller({ onClose, show, sellerId, reFresh }) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    shopName: "",
    email: "",
    phoneNo: "",
    addr: "",
    body: "",
  });

  const fetchSeller = () => {
    api.get(`Sellers/getSellerById/` + sellerId).then((res) => {
      setForm({
        firstName: res.data.result.sellers[0].first_name,
        lastName: res.data.result.  sellers[0].last_name,
        shopName: res.data.result.sellers[0].shop_name,
        email: res.data.result.sellers[0].email,
        phoneNo: res.data.result.sellers[0].phone_no,
        addr: res.data.result.sellers[0].adresse,
        body: res.data.result.sellers[0].body,
      });
    });
  };
  //
  useEffect(() => {
    if (sellerId) {
      fetchSeller();
    }
  }, [sellerId]);

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const _form = {
      first_name: form.firstName,
      last_name: form.lastName,
      shopName: form.shopName,
      email: form.email,
      phone_no: form.phoneNo,
      adresse: form.addr,
      body: form.body,
    };
    try {
      api.post(`Sellers/edit/` + sellerId, _form).then((res) => {
        if (res.data.status === "OK") {
          console.log("Seller Account  saved !");
          reFresh();
          if (onClose) onClose();
          Swal.fire({
            icon: "success",
            title: "Modifié avec succès",
            text: "Les informations de l'intermediaire ont été modifiées avec succès",
          });
        }
      });
    } catch (e) {
      console.log("Seller Account not saved !");
    }
  };

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title> Moddification intermediaire #{sellerId} </Modal.Title>
          <Button variant="light" onClick={onClose}>
            <i class="fas fa-times text-danger"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form onSubmit={handleSave} id="resetForm">
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                          type="text"
                          value={form.lastName}
                          required
                          onChange={(e) =>
                            handleChange("lastName", e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Prenoms</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={form.firstName}
                          onChange={(e) =>
                            handleChange("firstName", e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Téléphone</Form.Label>
                        <Form.Control
                          type="text"
                          value={form.phoneNo}
                          onChange={(e) =>
                            handleChange("phoneNo", e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Email <small className="text-danger">(*)</small>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          required
                          value={form.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Adresse</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="adresse de l'intermdiaire"
                        value={form.addr}
                        onChange={(e) => handleChange("addr", e.target.value)}
                      />
                    </Form.Group>

                    <Form.Label>Observation</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={form.body}
                      onChange={(e) => handleChange("body", e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 d-flex justify-content-start">
                    <Button variant="success" type="submit">
                      <i class="fas fa-save"></i> Valider
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button variant="secondary" onClick={onClose}>
                      Fermer
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" onClick={onClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default EditSeller;
