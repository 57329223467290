import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Sidebar(props) {
  return (
    <Fragment>
      <aside className="main-sidebar sidebar-light-primary elevation-0">
        <a href="#" className="brand-link">
          <span className="brand-text font-weight-light font-weight-bold text-center text-danger">
            E-Triangle App
          </span>
        </a>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <i className="nav-icon fas fa-house-user"></i>
                  <p>Tableau de Bord</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/product" className="nav-link">
                  <i className="nav-icon fas fa-box"></i>
                  <p>Catalogue</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/stock" className="nav-link">
                  <i class="nav-icon fas fa-boxes"></i>
                  <p>Stock</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/sellers" className="nav-link">
                  <i className="nav-icon fas fa-user-friends"></i>
                  <p>Intermediaire</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/orders" className="nav-link">
                  <i className="nav-icon fas fa-file-invoice"></i>
                  <p>Commande </p>
                </Link>
              </li>
              <li className="nav-item">
              <Link to="/payement" className="nav-link">
              <i className="nav-icon fas fa-hand-holding-usd"></i>
                  <p>Paiement </p>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/messages" className="nav-link">
                <i class="nav-icon fas fa-comment-dots"></i>
                  <p>Messages </p>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/setting" className="nav-link">
                <i className="nav-icon fas fa-tools"></i>
                  <p>Pramètre </p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </Fragment>
  );
}

export default Sidebar;
