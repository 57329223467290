import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import api from "../../api";
import Swal from "sweetalert2";
function DeleteSeller({ onClose, show, sellerId, reFresh }) {
  // const []
  const handleDelete = () => {
    // api.get(`Sellers/getSellerById/` + sellerId).then((res) => {
    //   setForm({
    //     firstName: res.data.result.sellers[0].first_name,
    //     lastName: res.data.result.sellers[0].last_name,
    //     shopName: res.data.result.sellers[0].shop_name,
    //     email: res.data.result.sellers[0].email,
    //     phoneNo: res.data.result.sellers[0].phone_no,
    //     addr: res.data.result.sellers[0].adresse,
    //     body: res.data.result.sellers[0].body,
    //   });
    // });
    api.delete(`Sellers/delete/${sellerId}`).then((res) => {
      if (res.data.result.feedback === "success") {
        Swal.fire(
          "Supprimé!",
          "Intermediaire supprimé avec succès.",
          "success"
        );
        reFresh();
        if (onClose) onClose();
      }
    });
  };
  return (
    <Fragment>
      <Modal show={show} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>
            Suppression intermediaire #{sellerId}
            <Button variant="light" onClick={onClose}>
              <i class="fas fa-times text-danger"></i>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <b className="font-weight-bold text-center">
            {" "}
            <i class="fas fa-exclamation-triangle text-danger"></i> La
            suppression est irrevesible!
          </b>
          <br />
          êtes-vous sur de vouloir vraiment supprimer ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Oui,Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default DeleteSeller;
