import React, { Fragment } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Button, Table } from "react-bootstrap";
import ListStock from "./ListStock";

function Stock(props) {
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Stock</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Stock</a>
                </li>
                <li className="breadcrumb-item active">Gestion stock</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Card >
              <div className="card-body p-0">
          <div class="table-responsive scrollbar mx-n1 px-1">
            <Table class="table fs--1 mb-0" id="myTable">
              <thead>
                <tr>
                  <th className="sort align-middle pe-5">PRODUIT</th>
                  <th>CATEGORIE</th>
                  <th>STOCK REEL</th>
                  <th>STOCK VIRTUEL</th>
                  {/* <th>Modifié le</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ListStock />
              </tbody>
            </Table>
          </div>
        </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Stock;
