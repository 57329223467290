import React, { Fragment, useEffect, useState } from "react";
import api from "../../api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
function EditCategorie({ onClose, show, reFresh, catId }) {
  const [form, setForm] = useState({
    catTitle: "",
    catBody: "",
  });

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  //
  const fetchCategorie = () => {
    api.get(`Categories/getCategorieById/` + catId).then((res) => {
      setForm({
        catTitle: res.data.result.categories[0].cat_title,
        catBody: res.data.result.categories[0].cat_body,
      });
    });
  };
  useEffect(() => {
    if (catId) {
      fetchCategorie();
    }
  }, [catId]);

  const handleSave = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("cat_title", form.catTitle);
    data.append("cat_body", form.catBody);

    api.post(`Categories/edit/` + catId, data).then((res) => {
      if (res.data.status === "OK") {
        console.log(res);
        reFresh();
        if (onClose) onClose();

        // Swal.fire({
        //   position: "top-end",
        //   icon: "success",
        //   title: "Categorie ajoutée avec succès",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
      } else {
        // this.ErrorSwal();
      }
    });
  };
  return (
    <Fragment>
      <Modal show={show} onHide={onClose}>
        <Form onSubmit={handleSave}>
          <Modal.Header>
            <Modal.Title>Modifier categorie # {catId}</Modal.Title>
            <button
              onClick={onClose}
              type="button"
              class="btn btn-light text-secondary"
            >
              <i class="fas fa-times"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titre categorie"
                required
                value={form.catTitle}
                onChange={(event) =>
                  handleChange("catTitle", event.target.value)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Decription</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={form.catBody}
                onChange={(event) =>
                  handleChange("catBody", event.target.value)
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onClose}>
              Fermer
            </Button>
            <Button variant="success" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default EditCategorie;
