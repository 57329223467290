import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import { Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Categories from "./Categories";
import SellerOption from "./SellerOption";
import api from "../../api";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link } from "react-router-dom";
import SubCatSelectBox from "./SubCatSelectBox";
//import { SearchBar } from '@rneui/themed'
const editorConfiguration = {
  toolbar: [
    "undo",
    "redo",
    "bold",
    "italic",
    "blockQuote",
    "heading",
    "link",
    "numberedList",
    "bulletedList",
  ],
};
function AddProduct(props) {
  const [form, setForm] = useState({
    prodName: "",
    prodBody: "",
    prodPu: "",
    optionPrice: "",
    subCategoryId: "",
    categoryId: "",
    prodQte: "",
    holdProdPu: "",
  });

  const [prodImage, setProdImage] = useState();
  const [prodBody, setProdBody] = useState("");
  const [prodCon, setProdCon] = useState("");
  const [category, setCategory] = useState("");

  const resetForm = () => {
    setForm({
      prodName: "",
      prodBody: "",
      prodPu: "",
      optionPrice: "",
      subCategoryId: "",
      categoryId: "",
      prodQte: "",
      holdProdPu: "",
      conditions :"",
    });
    setProdBody("");
    setProdCon("");
    setCategory("");
  };
  const handleChangeCat = (e) => {
    setCategory(e.target.value);
    console.log(category);
  };

  const handleChangeProdbody = (e, editor) => {
    console.log(editor.getData());
    setProdBody(editor.getData());
    // this.setState({
    //   message: editor.getData(),
    // });
  };
  const handleChangeCondition = (e, editor) => {
    console.log(editor.getData());
    setProdCon(editor.getData());
    // this.setState({
    //   message: editor.getData(),
    // });
  };

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleFile = (e) => {
    if (e.target.files) {
      setProdImage(e.target.files[0]);
    }
  };

  //

  //

  const handleSave = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("prod_name", form.prodName);
    data.append("prod_body", prodBody);
    data.append("conditions", prodCon);
    data.append("prod_image", prodImage);
    data.append("prod_pu", form.prodPu);
    data.append("prod_qte", form.prodQte);
    data.append("option_price", form.optionPrice);
    data.append("sub_category_id", form.subCategoryId);
    data.append("hold_prod_pu", form.holdProdPu);

    api.post(`Products/add`, data).then((res) => {
      if (res.data.status === "OK") {
        resetForm();
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // this.ErrorSwal();
      }
    });
  };
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ajouter produit</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to={"/product"}>Produit</Link>
                </li>
                <li className="breadcrumb-item active">Ajouter produit</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <div class="card text-start">
            <div className="card-body">

            
          <Form onSubmit={handleSave}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicNom">
                  <Form.Label>Titre</Form.Label>
                  <Form.Control
                    required
                    value={form.prodName}
                    onChange={(event) =>
                      handleChange("prodName", event.target.value)
                    }
                    type="text"
                    placeholder="saisir le titre du produit ici..."
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicNom">
                  <Form.Label>Categorie</Form.Label>
                  <Form.Select
                    required
                    className="form-control form-select"
                    // value={form.categoryId}
                    onChange={handleChangeCat}
                  >
                    <Categories />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicNom">
                  <Form.Label>Sous categorie</Form.Label>
                  <Form.Select
                    required
                    className="form-control form-select"
                    value={form.subCategoryId}
                    onChange={(event) =>
                      handleChange("subCategoryId", event.target.value)
                    }
                  >
                    <SubCatSelectBox idCategory={category} />
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <Form.Group className="mb-3" controlId="formBasicNom">
                  <Form.Label>Option de vente</Form.Label>
                  <Form.Select
                    required
                    className="form-control form-select"
                    value={form.optionPrice}
                    onChange={(event) =>
                      handleChange("optionPrice", event.target.value)
                    }
                  >
                    <SellerOption />
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicNom">
                  <Form.Label>Nouveau prix</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="FCFA"
                    value={form.prodPu}
                    onChange={(event) =>
                      handleChange("prodPu", event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicNom">
                  <Form.Label>Ancien prix</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="FCFA"
                    value={form.holdProdPu}
                    onChange={(event) =>
                      handleChange("holdProdPu", event.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control type="file" required onChange={handleFile} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <CKEditor
                    config={editorConfiguration}
                    editor={ClassicEditor}
                    data=""
                    onChange={handleChangeProdbody}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Conditions partuiculieres</Form.Label>
                  <CKEditor
                    config={editorConfiguration}
                    editor={ClassicEditor}
                    data=""
                    onChange={handleChangeCondition}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <Card className="leftCardX shadow-0">
                  <Card.Body>
                    <Form.Group className="mb-3 " controlId="formBasicNom">
                      <Form.Label>Ajouter en Stock</Form.Label>
                      <div className="">
                        <Form.Control
                          type="number"
                          placeholder="Quantité"
                          value={form.prodQte}
                          onChange={(event) =>
                            handleChange("prodQte", event.target.value)
                          }
                        />
                      </div>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Button variant="success" type="submit">
                    <i class="fas fa-save"></i> Valider
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
             </div></div>
        </Container>
      </section>
    </Fragment>
  );
}

export default AddProduct;
