import React from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import api from "../../api";
import Swal from "sweetalert2"
function DeleteCategorie({ onClose, show, reFresh, catId }) {
    const handleDelete = () => {
        api.delete(`Categories/delete/${catId}`).then((res) => {
          if (res.data.result.feedback === "success") {
            Swal.fire(
              "Supprimé!",
              "Categorie supprimée avec succès.",
              "success"
            );
            reFresh();
            if (onClose) onClose();
          }
        });
      };
  return (
    <Fragment>
      <Modal show={show} onHide={onClose}>
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between">
            Suppression categorie #{catId}
            {/* <Button variant="light" onClick={onClose}>
              <i class="fas fa-times text-danger"></i>
            </Button> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <b className="font-weight-bold text-center">
            {" "}
            <i class="fas fa-exclamation-triangle text-danger"></i> La
            suppression est irrevesible!
          </b>
          <br />
          êtes-vous sur de vouloir vraiment supprimer ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Oui,Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default DeleteCategorie;
