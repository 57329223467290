import React, { useState, Fragment, useEffect, useRef } from "react";
import api from "../../api";
import dateFormat, { masks } from "dateformat";
import AddCategorie from "./AddCategorie";
import EditCategorie from "./EditCategorie";
import DeleteCategorie from "./DeleteCategorie";

import "primeicons/primeicons.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";

///
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";

function Categorie(props) {
  const [show, setShow] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [catid, SetCatid] = useState("");
  const handleClose = () => setShow(false);

  const handleEdit = (id) => {
    setShow(true);
    setEditMode(true);
    SetCatid(id);
    setCreateMode(false);
    setDeleteMode(false);
  };
  const handleDelete = (id) => {
    SetCatid(id);
    setShow(true);
    setDeleteMode(true);
    setCreateMode(false);
    setEditMode(false);
  };

  const handleCreate = () => {
    setShow(true);
    setCreateMode(true);
    setEditMode(false);
    setDeleteMode(false);
  };

  const fetchCategorie = () => {
    api.get(`Categories/`).then((res) => {
      const categories = res.data.result.categories;
      console.log(categories);
      setCategorie(categories);
    });
  };
  useEffect(() => {
    fetchCategorie();
  }, []);
  //
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date);
  };

  //
  const [products, setProducts] = useState(null);

  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          className="mr-2"
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedCategory || !selectedCategory.length}
        />
      </div>
    );
  };
  //
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };
  //
  const openNew = () => {
    setProduct("");
    setSubmitted(false);
    setProductDialog(true);
  };

  //
  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };
  //
  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const dateTemplate = (rowData) => {
    return rowData.created instanceof Date
      ? rowData.created.toLocaleDateString("fr")
      : new Date(rowData.created).toLocaleDateString("fr");
    //rowData.created.toLocaleDateString("fr");
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editProduct(rowData)}
        />

        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  const renderHeader = () => {
    return (
      <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
        <h4 className="m-0">Categorie produit</h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
          />
        </span>
      </div>
      // <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
      //   <h4 className="m-0">Categorie produit</h4>
      //   <span className="p-input-icon-left">
      //     <i className="pi pi-search" />
      //     <InputText
      //       value={globalFilterValue}
      //       onChange={onGlobalFilterChange}
      //       placeholder="Rechercher..."
      //     />
      //   </span>
      // </div>
    );
  };

  //
  const header = renderHeader();
  return (
    <Fragment>
      <div className="card">
        <Toast ref={toast} />
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <div className="card-body p-0">
            <div class="table-responsive">
              <DataTable
                ref={dt}
                value={categorie}
                selection={selectedCategory}
                onSelectionChange={(e) => setSelectedCategory(e.value)}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Affiche {first} de {last} à {totalRecords} Categories"
                globalFilter={globalFilter}
                emptyMessage="Auccune categorie de produit trouvée."
                header={header}
                filterDisplay="menu"
                tableStyle={{ minWidth: "50rem" }}>
                <Column selectionMode="multiple" exportable={false}></Column>
                <Column field="cat_title" header="Titre" sortable></Column>
                <Column field="cat_body" header="Description" sortable></Column>
                <Column
                  field="created"
                  header="Date"
                  sortable
                  body={dateTemplate}
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "12rem" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      {createMode && (
        <AddCategorie
          show={show}
          handleClose={handleClose}
          reFresh={fetchCategorie}
        />
      )}
      {editMode && (
        <EditCategorie
          show={show}
          onClose={handleClose}
          reFresh={fetchCategorie}
          catId={catid}
        />
      )}
      {deleteMode && (
        <DeleteCategorie
          show={show}
          onClose={handleClose}
          reFresh={fetchCategorie}
          catId={catid}
        />
      )}
    </Fragment>
  );
}

export default Categorie;
