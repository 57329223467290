import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Dasboard from "./components/dashboard/Dasboard";
import Order from "./components/orders/Order";
import Product from "./components/product/Product";
import Seller from "./components/sellers/Seller";
import Message from "./messages/Message";
import Payement from "./components/payement/Payement";
import Setting from "./components/setings/Setting";
import AddProduct from "./components/product/AddProduct";
import AddSeller from "./components/sellers/AddSeller";
import Stock from "./components/stock/Stock";
import ProductView from "./components/product/ProductView";
import EditSeller from "./components/sellers/EditSeller";
import OrderView from "./components/orders/OrderView";
function Layout(props) {
  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content">
          <Routes>
            <Route>
              <Route index element={<Dasboard />} />
              <Route path="orders" element={<Order />} />
              <Route path="product" element={<Product />} />
              <Route path="stock" element={<Stock />} />
              <Route path="sellers" element={<Seller />} />
              <Route path="messages" element={<Message />} />
              <Route path="payement" element={<Payement />} />
              <Route path="setting" element={<Setting />} />
              <Route path="addProduct" element={<AddProduct />} />
              <Route path="addSeller" element={<AddSeller />} />
              <Route path="product-view" element={<ProductView />} />
              <Route path="edit-seller" element={<EditSeller />} />
              <Route path="view-order" element={<OrderView />} />
            </Route>
          </Routes> 
        </section>
      </div>
    </Fragment>
  );
}

export default Layout;
