import React, { Fragment } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Categorie from "../categorie/Categorie";
import SousCategorie from "../souscategorie/SousCategorie";
function Setting(props) {
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Paramètre</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Blank Page</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Categories">
            <Categorie />
          </Tab>
          <Tab eventKey="profile" title="Sous Categories">
          <SousCategorie/>
          </Tab>
          <Tab eventKey="users" title="Utilisateurs">
            <div className="card">
              <div className="card-body"></div>
            </div>
          </Tab>
        </Tabs>
      </section>
    </Fragment>
  );
}

export default Setting;
