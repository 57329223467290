import React, { useEffect } from "react";
import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Card, Image } from "react-bootstrap";
import Categories from "./Categories";
import SellerOption from "./SellerOption";
import api from "../../api";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState } from "react";
import SubCatSelectBox from "./SubCatSelectBox";
const editorConfiguration = {
  toolbar: [
    "undo",
    "redo",
    "bold",
    "italic",
    "blockQuote",
    "heading",
    "link",
    "numberedList",
    "bulletedList",
  ],
};

function EditProduct({ onClose, show, prodId, reFresh }) {
  const [form, setForm] = useState({
    prodName: "",
    prodBody: "",
    prodPu: "",
    holdProdPu: "",
    optionPrice: "",
    subCategoryId: "",
    categoryId: "",
    prodQte: "",
    //prodSatut: "",
  });

  const [prodImage, setProdImage] = useState();
  const [prodBody, setProdBody] = useState("");
  const [category, setCategory] = useState("");
  const [prodSatut, setProdStatut] = useState(0);

  const handleChangeProdbody = (e, editor) => {
    console.log(editor.getData());
    setProdBody(editor.getData());
  };

  const handleChangeCat = (e) => {
    setCategory(e.target.value);
    console.log(category);
  };
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const hadleCheck = (e) => {
    const checked = e.target.checked;
    if (checked) setProdStatut(1);
  };

  const handleFile = (e) => {
    if (e.target.files) {
      setProdImage(e.target.files[0]);
    }
  };
  const fetchProduct = () => {
    api.get(`Products/getProduct/` + prodId).then((res) => {
      setForm({
        prodName: res.data.result.product.prod_name,
        prodImage: res.data.result.product.prod_image,
        prodBody: res.data.result.product.prod_body,
        prodPu: res.data.result.product.prod_pu,
        holdProdPu: res.data.result.product.hold_prod_pu,
        // prodSatut: res.data.result.product.statut_prod,
      });
      setProdStatut(res.data.result.product.statut_prod);
    });
  };
  useEffect(() => {
    if (prodId) {
      fetchProduct();
    }
  }, [prodId]);

  const handleSave = (e) => {
    e.preventDefault();
    // alert(form.prodSatut);
    const data = new FormData();
    data.append("prod_name", form.prodName);
    data.append("prod_body", prodBody);
    data.append("prod_image", prodImage);
    data.append("prod_pu", form.prodPu);
    data.append("hold_prod_pu", form.holdProdPu);
    data.append("prod_qte", form.prodQte);
    data.append("option_price", form.optionPrice);
    data.append("sub_category_id", form.subCategoryId);
    //data.append("statut_prod", prodSatut);

    api.post(`Products/edit/` + prodId, data).then((res) => {
      if (res.data.status === "OK") {
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Produit modifié avec succes",
          showConfirmButton: false,
          timer: 1500,
        });
        reFresh();
        if (onClose) onClose();
      } else {
        // this.ErrorSwal();
      }
    });
  };
  const renderStatut = () => {
    let buttonAction;
    if (prodSatut === 1) {
      buttonAction = (
        <Button variant="outline-danger" onClick={() => updateStatut(0)}>
          Desactiver l'article
        </Button>
      );
    } else if(prodSatut === 0){
      buttonAction = (
        <Button variant="outline-success" onClick={() => updateStatut(1)}>
          Reactiver l'article
        </Button>
      );
    }
    return buttonAction;
  };

  const updateStatut = (idStatut) => {
    const data = new FormData();
    data.append("statut_prod", idStatut);
    api.post(`Products/updateStatutProd/` + prodId, data).then((res) => {
      if (res.data.status === "OK") {
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Produit modifié avec succes",
          showConfirmButton: false,
          timer: 1500,
        });
        reFresh();
        if (onClose) onClose();
        window.location.reload();
      } else {
        // this.ErrorSwal();
      }
    });
  };

  return (
    <Fragment>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Modification produit N° {prodId}</Modal.Title>
          {renderStatut()}
          <button type="button" className="btn btn-light" onClick={onClose}>
            <i class="fas fa-times text-danger"></i>{" "}
          </button>
        </Modal.Header>
        <Modal.Body>
          <section>
            <Form onSubmit={handleSave}>
              <Container>
                <Row className="g-5">
                  <Col md="8">
                    <Form.Group className="mb-3" controlId="formBasicNom">
                      <Form.Label>Titre produit</Form.Label>
                      <Form.Control
                        required
                        value={form.prodName}
                        onChange={(event) =>
                          handleChange("prodName", event.target.value)
                        }
                        type="text"
                        placeholder="saisir le titre du produit ici..."
                      />
                    </Form.Group>
                    {/* categorie de produi*/}
                    <Form.Group className="mb-3" controlId="formBasicNom">
                      <Form.Label>Categorie </Form.Label>
                      <Form.Select
                        required
                        className="form-control form-select"
                        onChange={handleChangeCat}
                      >
                        <Categories catId={form.categoryId} />
                      </Form.Select>
                    </Form.Group>
                    {/* sous-categorie de produit */}
                    <Form.Group className="mb-3" controlId="formBasicNom">
                      <Form.Label>Sous categorie</Form.Label>
                      <Form.Select
                        required
                        className="form-control form-select"
                        value={form.subCategoryId}
                        onChange={(event) =>
                          handleChange("subCategoryId", event.target.value)
                        }
                      >
                        <SubCatSelectBox idCategory={category} />
                      </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Nouvelle Image</Form.Label>
                      <Form.Control type="file" onChange={handleFile} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Description du produit</Form.Label>
                      <CKEditor
                        config={editorConfiguration}
                        editor={ClassicEditor}
                        data={form.prodBody}
                        onChange={handleChangeProdbody}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Button variant="success" type="submit">
                        <i class="fas fa-save"></i> Valider
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    {/* <Form.Group className="mb-3" controlId="formBasicNom">
                      <Form.Label>Option de vente</Form.Label>
                      <Form.Select
                        required
                        className="form-control form-select"
                        value={form.optionPrice}
                        onChange={(event) =>
                          handleChange("optionPrice", event.target.value)
                        }
                      >
                        <SellerOption />
                      </Form.Select>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicNom">
                      <Form.Label>Nouveau prix</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="FCFA"
                        value={form.prodPu}
                        onChange={(event) =>
                          handleChange("prodPu", event.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicNom">
                      <Form.Label>Ancien prix</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="FCFA"
                        value={form.holdProdPu}
                        onChange={(event) =>
                          handleChange("holdProdPu", event.target.value)
                        }
                      />
                    </Form.Group>
                    {/* <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={hadleCheck}
                        id="flexCheckDefault"
                      />
                      <label
                        class="form-check-label font-weight-bold"
                        for="flexCheckDefault"
                      >
                        Activé le produit
                      </label>
                    </div> */}
                    <Card className="leftCard shadow-0 border-0">
                      <Card.Body>
                        <label>Image Actuelle</label>
                        <Image
                          //   style={{ width: "100px" }}
                          fluid
                          src={"http://medias.e-triangle.com/" + form.prodImage}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Form>
          </section>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default EditProduct;
