import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import api from "../../api";

function SellerOption(props) {
  const [selleroption, SetSellerOption] = useState([]);

  const fetchSellerOption = () => {
    api.get(`Selleroptions/index`).then((res) => {
      const selleroption = res.data.result.selleroptions;
      SetSellerOption(selleroption);
    });
  };
  useEffect(() => {
    fetchSellerOption();
  }, []);

  return (
    <Fragment>
      <option selected disabled value="">
        Selectionnez...
      </option>
      {selleroption.map((selleroptions, id) => (
        <option value={selleroptions.id} key={id}>
          {selleroptions.titlte}
        </option>
      ))}
    </Fragment>
  );
}

export default SellerOption;
