import React, { useEffect } from "react";
import { useState, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import api from "../../api";
import Categories from "../product/Categories";

function EditSousCategorie({ show, onClose, reFresh,subCatId }) {

  const [form, setForm] = useState({
    subCatTitle: "",
    subCatBody: "",
    categoryId: "",
  });

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  //
  const fetchSubCategorie = () => {
    api.get(`SubCategories/getSubCategorieById/` + subCatId).then((res) => {
      setForm({
        subCatTitle: res.data.result.subCategories[0].sub_cat_title,
        subCatBody: res.data.result.subCategories[0].sub_cat_body,
        categoryId: res.data.result.subCategories[0].category_id,
      });
    });
  };
  useEffect(() => {
    if (subCatId) {
      fetchSubCategorie();
    }
  }, [subCatId]);

  const handleSave = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("sub_cat_title", form.subCatTitle);
    data.append("sub_cat_body", form.subCatBody);
    data.append("category_id", form.categoryId);

    api.post(`SubCategories/edit/` + subCatId, data).then((res) => {
      if (res.data.status === "OK") {
        console.log(res);
        reFresh();
        if (onClose) onClose();

        // Swal.fire({
        //   position: "top-end",
        //   icon: "success",
        //   title: "Categorie ajoutée avec succès",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
      } else {
        // this.ErrorSwal();
      }
    });
  };
  return (
    <Fragment>
      <Modal show={show} onHide={onClose}>
        <Form onSubmit={handleSave}>
          <Modal.Header>
            <Modal.Title>Modifier sous-categorie #:{subCatId}</Modal.Title>
            <button
              onClick={onClose}
              type="button"
              class="btn btn-light text-secondary"
            >
              <i class="fas fa-times"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titre categorie"
                required
                value={form.subCatTitle}
                onChange={(event) =>
                  handleChange("subCatTitle", event.target.value)
                }
              />
            </Form.Group>
            {/* <Button variant="primary">Primary</Button> */}
            <Form.Group className="mb-3" controlId="formBasicNom">
              <Form.Label>Categorie produit</Form.Label>
              <Form.Select
                required
                className="form-control form-select"
                value={form.categoryId}
                onChange={(event) =>
                  handleChange("categoryId", event.target.value)
                }
              >
                <Categories />
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Decription</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={form.subCatBody}
                onChange={(event) =>
                  handleChange("subCatBody", event.target.value)
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onClose}>
              Fermer
            </Button>
            <Button variant="success" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default EditSousCategorie;
