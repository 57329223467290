import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import api from "../../api";
function Categories(props) {
  const [category, SetCategory] = useState([]);

  const fetchCategory = () => {
    api.get(`categories/index`).then((res) => {
      const category = res.data.result.categories;
      SetCategory(category);
    });
  };
  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <Fragment>
      <option selected disabled value="">
        Selectionnez...
      </option>
      {category.map((categorie, id) => (
        <option value={categorie.id} key={id}>
          {categorie.cat_title}
        </option>
      ))}
    </Fragment>
  );
}

export default Categories;
