import React from "react";
import { Fragment } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";
import { useState } from "react";
import Swal from "sweetalert2";
import api from "../../api";
import { Navigate } from "react-router";
var Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3500,
});

function Login(props) {
  const [isloading, setIsloading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [redirect, setRedirect] = useState(false);

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  const ErrorSwal = () => {
    Toast.fire({
      icon: "error",
      title: "Mot de passe ou identifiant inconnu",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("username", form.email);
    data.append("password", form.password);
    setIsloading(true);
    api.post(`Users/login`, data).then((res) => {
      if (res.data.result.feedback === "success") {
        //sessionStorage.setItem("accessToken", res.data.result.token);
        sessionStorage.setItem("userId", res.data.result.payload.id);
        sessionStorage.setItem("userName", res.data.result.payload.username);
        //sessionStorage.setItem("userType", res.data.result.payload.type);
        setRedirect(true);
      } else if (res.data.result.feedback === "failed") {
        setIsloading(false);
        ErrorSwal();
      }
    });
  };
  if (redirect) return <Navigate to="/" />;
  const spiner= '<div class="spinner-grow spinner-grow-sm" role="status"></div>'
  return (
   
    <Fragment>

      <section>
        <Container>
          <Row>
            <Col>
              <div class="text-center mt-2">
                <p className="text-secondary text-center">
                  Connectez-vous à votre compte pour continue
                </p>
                {/* <p class="lead">Connectez-vous à votre compte pour continue</p> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <main class="form-signin w-100 m-auto shadow-sm">
                <form onSubmit={handleSave}>
                  <div className="logoEtriagnle">
                    <img
                      class="mb-2  shadow-sm appLogo"
                      src="/img/Logo_e-triangle.png"
                      alt="Logo_E-Triangle"
                    />
                  </div>

                  {/* <h1 class="h3 mb-3 fw-normal text-center mt-5">
                    Connectez vous
                  </h1>
         */}

                  <div class="form-floating">
                    <label for="floatingInput">Adresse email</label>
                    <input
                      type="email"
                      class="form-control mb-3"
                      id="floatingInput"
                      onChange={(event) =>
                        handleChange("email", event.target.value)
                      }
                    />
                  </div>
                  <div class="form-floating">
                    <label for="floatingPassword">Mot de passe</label>
                    <input
                      type="password"
                      class="form-control"
                      id="floatingPassword"
                      onChange={(event) =>
                        handleChange("password", event.target.value)
                      }
                    />
                  </div>

                  <div class="form-check text-start my-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="remember-me"
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Se souvenir de moi
                    </label>
                  </div>
                  <button class="btn btn-primary w-100 py-2" type="submit">
                    
                    {isloading ? "Connexion  en cours..." : "Se connecter"}
                  
                  </button>
                  <p class="mt-5 mb-3 text-body-secondary text-center">
                    &copy; e-triangle
                  </p>
                </form>
              </main>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Login;
