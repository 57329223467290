import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import api from "../../api";
import dateFormat, { masks } from "dateformat";
import { Dropdown } from "react-bootstrap";
import EditSeller from "./EditSeller";
import DeleteSeller from "./DeleteSeller";
function SellerList() {
  const [seller, SetSeller] = useState([]);
  const [sellerId, setSellerId] = useState();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const fetchSeller = () => {
    api.get(`Sellers/`).then((res) => {
      const sellers = res.data.result.sellers;
      console.log(sellers);
      SetSeller(sellers);
      
    });
  };

  //
  const handleEdit = (id) => {
    setShow(true);
    setSellerId(id);
    setEditMode(true);
    setDeleteMode(false);
  };
  const handleDelete = (id) => {
    setShow(true);
    setSellerId(id);
    setDeleteMode(true);
    setEditMode(false);
  };
  //
  useEffect(() => {
    fetchSeller();
  }, []);

  return (
    <Fragment>
      {seller.map((selleur) => (
        <tr>
          {/* <td></td> */}
          <td>{selleur.last_name + " " + selleur.first_name}</td>
          <td className="email align-middle white-space-nowrap pe-5">
            <a
              class="fw-semi-bold"
              href="mailto:contact@e-triangle.com"
              spellcheck="false"
            >
              {selleur.email}
            </a>
          </td>
          <td className="sort align-middle text-center">{selleur.phone_no}</td>
          <td>{dateFormat(selleur.created, "dd/mm/yyyy")}</td>
          <td>
            {" "}
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleEdit(selleur.id)}>
                  <i class="far fa-edit text-success"></i> Modifier
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(selleur.id)}>
                  <i class="fas fa-trash text-danger"></i> Supprimer
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <i class="fas fa-bars text-info"></i> Detail
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      ))}
      {editMode && (
        <EditSeller
          show={show}
          onClose={handleClose}
          sellerId={sellerId}
          reFresh={fetchSeller}
        />
      )}
      {deleteMode && (
        <DeleteSeller
          show={show}
          onClose={handleClose}
          sellerId={sellerId}
          reFresh={fetchSeller}
        />
      )}
    </Fragment>
  );
}

export default SellerList;
