import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import api from "../../api";
import { useEffect } from "react";

function StatusSelecBox(props) {
  const [statut, setStatut] = useState([]);

  const fetchOrderStatut = () => {
    api.get(`StatutOrders/index`).then((res) => {
      const statut = res.data.result.statutOrders;
      setStatut(statut);
    });
  };
  useEffect(() => {
    fetchOrderStatut();
  }, []);

  return (
    <Fragment>
      <option selected disabled value="">
        Selectionnez...
      </option>
      {statut.map((statuts, id) => (
        <option value={statuts.id} key={id}>
          {statuts.title}
        </option>
      ))}
    </Fragment>
  );
}

export default StatusSelecBox;
