import React, { useState, Fragment, useEffect } from "react";
import Button from "react-bootstrap/Button";
import api from "../../api";
import dateFormat, { masks } from "dateformat";
import AddSousCategorie from "./AddSousCategorie";
import EditSousCategorie from "./EditSousCategorie";
import DeleteSousCategorie from "./DeleteSousCategorie";
import 'primeicons/primeicons.css';
        
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";                                       
import { Calendar } from 'primereact/calendar';       
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

function SousCategorie(props) {
  const [show, setShow] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [subCategorie, setSubCategorie] = useState([]);
  const [subCatId, setSubCatId] = useState("");
  const handleClose = () => setShow(false);

  const handleCreate = () => {
    setShow(true);
    setCreateMode(true);
    setEditMode(false);
    setDeleteMode(false);
  };
  const handleEdit = (id) => {
    setShow(true);
    setEditMode(true);
    setSubCatId(id);
    setCreateMode(false);
    setDeleteMode(false);
  };
  const handleDelete = (id) => {
    setSubCatId(id);
    setShow(true);
    setDeleteMode(true);
    setCreateMode(false);
    setEditMode(false);
  };
  const fetchSubCategorie = () => {
    api.get(`SubCategories/`).then((res) => {
      const subCategories = res.data.result.subCategories;
      console.log(subCategories);
      setSubCategorie(subCategories);
    });
  };
  useEffect(() => {
    fetchSubCategorie();
  }, []);
//
const [globalFilterValue, setGlobalFilterValue] = useState('');

const [filters, setFilters] = useState({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;

  setFilters(_filters);
  setGlobalFilterValue(value);
};
const renderHeader = () => {
  return (
      <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
          <h4 className="m-0">Sous-Categorie produit</h4>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Rechercher..." />
          </span>
      </div>
  );
};
// 
const header = renderHeader();
//
  return (
    <Fragment>
      <div className="card">
        <div class="card-header d-flex justify-content-end">
          <Button variant="primary" onClick={handleCreate}>
            <i class="fas fa-plus-circle"></i> Ajouter
          </Button>
        </div>
        <div className="card-body p-0">
        <DataTable value={subCategorie} header={header} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} 
            filters={filters} filterDisplay="menu" globalFilterFields={['sub_cat_title', 'sub_cat_body', 'created']}
            emptyMessage="Auccune sous-categorie de produit trouvée."
            >
                <Column field="sub_cat_title" header="Titre"  sortable ></Column>
                <Column field="sub_cat_body" header="Description" sortable ></Column>
                <Column field="created" header="Date" sortable  ></Column>
                <Column field="" header="Action"></Column>
            </DataTable>
          {/* <table className="table table-sm table-hover">
            <tr>
              <th>Titre</th>
              <th>Categorie</th>
              <th>Description</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
            <tbody>
              {subCategorie.map((subCat) => (
                <tr className=""  key={subCat.id}>
                  <td>
                    {subCat.sub_cat_title}
                  </td>
                  <td>{subCat.category.cat_title}</td>
                  <td>{subCat.sub_cat_body}</td>
                  <td>{dateFormat(subCat.created, "dd/mm/yyyy")}</td>
                  <td className="d-flex justify-content-around">
                    <button
                      type="button"
                      class="btn btn-light btn-sm"
                      onClick={() => handleEdit(subCat.id)}
                    >
                      <i class="fas fa-edit text-success"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-light btn-sm"
                      onClick={() => handleDelete(subCat.id)}
                    >
                      <i class="far fa-trash-alt text-danger"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>

      {createMode && (
        <AddSousCategorie
          show={show}
          handleClose={handleClose}
          reFresh={fetchSubCategorie}
        />
      )}
      {editMode && (
        <EditSousCategorie
          show={show}
          onClose={handleClose}
          reFresh={fetchSubCategorie}
          subCatId={subCatId}
        />
      )}
      {deleteMode && (
        <DeleteSousCategorie
          show={show}
          onClose={handleClose}
          reFresh={fetchSubCategorie}
          subCatId={subCatId}
        />
      )}
    </Fragment>
  );
}

export default SousCategorie;
