import React from "react";
import { useState, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import api from "../../api";
import Swal from "sweetalert2";

function AddCategorie({ show, handleClose, reFresh }) {
  const [form, setForm] = useState({
    catTitle: "",
    catBody: "",
  });

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  //  
  const resetAfterSave = () => {
    setForm({
      catTitle: "",
      catBody: "",
    });
  };
  const handleSave = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("cat_title", form.catTitle);
    data.append("cat_body", form.catBody);

    api.post(`Categories/add`, data).then((res) => {
      if (res.data.status === "OK") {
        console.log(res);
        resetAfterSave();
        reFresh();
        // Swal.fire({
        //   position: "top-end",
        //   icon: "success",
        //   title: "Categorie ajoutée avec succès",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
      } else {
        // this.ErrorSwal();
      }
    });
  };
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSave}>
          <Modal.Header>
            <Modal.Title>Ajouter categorie</Modal.Title>
            <button
              onClick={handleClose}
              type="button"
              class="btn btn-light text-secondary"
            >
              <i class="fas fa-times"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titre categorie"
                required
                value={form.catTitle}
                onChange={(event) =>
                  handleChange("catTitle", event.target.value)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Decription</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={form.catBody}
                onChange={(event) =>
                  handleChange("catBody", event.target.value)
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
            <Button variant="success" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default AddCategorie;
