import React, { Fragment, useState } from "react";
import api from "../../api";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Badge, Image } from "react-bootstrap";
import EnterStock from "./EnterStock";
import { Tooltip } from "react-tooltip";
import TextTruncate from "react-text-truncate"; // recommend
import "react-tooltip/dist/react-tooltip.css";
function ListStock(props) {
  const [stock, setStock] = useState([]);
  const [show, setShow] = useState(false);
  const [idProd, setIdProd] = useState();
  const [outStock, setOutStock] = useState(false);
  const [enterStock, setEnterStock] = useState(false);

  const handleOutStock = () => {};
  const handleEnterStock = () => {};

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleEditStock = (id) => {
    setShow(true);
    setIdProd(id);
  };
  const fetchStock = () => {
    api.get(`Stocks/`).then((res) => {
      const stocks = res.data.result.stocks;
      console.log(stocks);
      setStock(stocks);
    });
  };
  //
  useEffect(() => {
    fetchStock();
  }, []);

  //
  const handleSave = () => {
    api.post();
  };
  return (
    <Fragment>
      {stock.map((stocks) => (
        <tr>
          <td>
          <Image
              style={{ width: "50px" }}
              src={"https://medias.e-triangle.com/" + stocks.product.prod_image}
              thumbnail
              className="mr-1"
            />
            <TextTruncate
              line={1}
              element="span"
              truncateText="…"
              text={stocks.product.prod_name}
            />
          </td>
          <td>
            <Badge bg="secondary">
              {stocks.product.sub_category.sub_cat_title}
            </Badge>
          </td>
          <td className="text-center">{stocks.qte}</td>
          <td className="text-center">{stocks.qte}</td>
          <td className="d-flex justify-content-between">
            <Button
              title="Sortie de stock"
              variant="light"
              size="sm"
              onClick={() => handleEditStock(stocks.product_id)}
            >
              <i class="fas fa-arrow-up text-danger"></i>
            </Button>
            <Button
              title="Entrée de stock"
              variant="light"
              size="sm"
              onClick={() => handleEditStock(stocks.product_id)}
            >
              <i class="fas fa-arrow-down text-success"></i>
            </Button>
          </td>
        </tr>
      ))}
      <EnterStock handleClose={handleClose} show={show} idProd={idProd} />
    </Fragment>
  );
}

export default ListStock;
