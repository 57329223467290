import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import api from "../../api";
import Badge from "react-bootstrap/Badge";
import { Card, Image } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Dropzone, { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

function ProductView(props) {
  const [prod, SetProd] = useState({
    prodName: "",
    prodPu: "",
    prodImage: "",
    prodBody: "",
    prodPu: "",
  });
  const [prodImages, setProdImages] = useState();
  const myLink = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("IDPROD");
  };

  const fetchProduct = () => {
    api.get(`Products/getProduct/` + myLink()).then((res) => {
      SetProd({
        prodName: res.data.result.product.prod_name,
        prodImage: res.data.result.product.prod_image,
        prodBody: res.data.result.product.prod_body,
        prodPu: res.data.result.product.prod_pu,
      });
    });
  };

  useEffect(() => {
    fetchProduct();
  }, []);
  const handleFile = (e) => {
    if (e.target.files) {
      setProdImages(e.target.files[0]);
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("prod_image", prodImages);
    data.append("product_id", myLink());
    api.post(`Images/add`, data).then((res) => {
      if (res.data.status === "OK") {
        // resetForm();
        console.log(res);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // this.ErrorSwal();
      }
    });
  };
  return (
    <Fragment>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Detail catalogue produit</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to={"/product"}>Produit</Link>
                </li>
                <li className="breadcrumb-item active">Ajouter produit</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="card p-5">
        <Container>
          <Row>
            <Col>
              {/* <Image
              style={{ width: "350px" }}
                src={"http://medias.e-triangle.com/" + prod.prodImage}
                
              /> */}
              <Image
                style={{ width: "350px" }}
                src={"http://medias.e-triangle.com/" + prod.prodImage}
              />
            </Col>
            <Col>
              <p className="font-weight-bold">{prod.prodName}</p>

              <div></div>
              <Badge bg="secondary">PRIX: {prod.prodPu} CFA</Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="font-weight-bold fs1">Description</p>
              <p>{parse(prod.prodBody)}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <div className="">
                    <form action="" onSubmit={handleSave}>
                      <div>
                        <label for="formFileLg" class="form-label">
                          Ajouter plus d'images
                        </label>
                        <input
                          class="form-control form-control-lg mb-3"
                          type="file"
                          required
                          onChange={handleFile}
                        />
                      </div>
                      <div className="mb-3">
                        <button type="submit" class="btn btn-success">
                          Enregistrer
                        </button>
                      </div>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default ProductView;
