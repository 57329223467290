import React, { Fragment, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import api from "../../api";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import DeleteProduct from "./DeleteProduct";
import EditProduct from "./EditProduct";
import TextTruncate from "react-text-truncate"; // recommend

import DataTable from "react-data-table-component";


function ProductList() {
  const [product, SetProduct] = useState([]);
  //
  const [prodId, setProdId] = useState();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [pending, setPending] = useState(true);
  const fetchProduct = () => {
    api.get(`Products/catalogue`).then((res) => {
      const products = res.data.result.products;
      console.log(products);
      SetProduct(products);
      if (products) {
        const timeout = setTimeout(() => {
          SetProduct(products);
          setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
      } else {
        setPending(false);
      }
    });
  };
  //
  const handleEdit = (id) => {
    setShow(true);
    setProdId(id);
    setEditMode(true);
    setDeleteMode(false);
  };

  const handleDelete = (id) => {
    setShow(true);
    setProdId(id);
    setDeleteMode(true);
    setEditMode(false);
  };
  //
  useEffect(() => {
    fetchProduct();
  }, []);
  const columns = [
    {
      name: "",
      selector: (row) => (<Image  src={"http://medias.e-triangle.com/" + row.prod_image}thumbnail/>
      ),
      sortable: true,
      width: "6%",   
    },
    {
      name: "Nom produit",
      selector: (row) => (
        <Link
        to={"/product-view?IDPROD=" + row.id}
        className="fw-semi-bold line-clamp-3 mb-0"
      >
        <TextTruncate
          line={4}
          element="span"
          truncateText="…"
          text={row.prod_name}
        />
      </Link>
      ),
      sortable: true, width: "28%",
    },
    {
      name: "Prix U.",
      selector: (row) => row.prod_pu,
      sortable: true,
       width: "5%",
    },
    {
      name: "Categorie",
      selector: (row) => row.sub_category.sub_cat_title,
      sortable: true,
      width: "10%", 
    },
    {
      name: "Statut",
      selector: (row) =>row.statut_prod,
      sortable: true,
      width: "7%", 
    },
    {
      name: "",
      selector: (row) => ( 
      <button type="button"  onClick={() => handleEdit(row.id)} class="btn btn-light btn-sm"><i class="far fa-edit text-success"></i></button>),
      sortable: true,
      width: "5%", 
    },
    {
      name: "",
      selector: (row) => ( 
      <button type="button"  onClick={() => handleDelete(row.id)} class="btn btn-light btn-sm"><i class="fas fa-trash text-danger"></i></button>),
      sortable: true,
      width: "5%", 
    },
  ];
  //
  const paginationComponentOptions = {
    rowsPerPageText: "Ligne par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };





  
  return (
    <Fragment>
      <DataTable
        columns={columns}
        data={product}
        progressPending={pending}
        // progressComponent={<CustomLoader />}
        selectableRows
        pagination
        paginationComponentOptions={paginationComponentOptions}
      />

      {editMode && (
        <EditProduct
          show={show}
          onClose={handleClose}
          prodId={prodId}
          reFresh={fetchProduct}
        />
      )}
      {deleteMode && (
        <DeleteProduct
          show={show}
          onClose={handleClose}
          prodId={prodId}
          reFresh={fetchProduct}
        />
      )}
    </Fragment>
  );
}
export default ProductList;
