import React, { Fragment, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import dateFormat, { masks } from "dateformat";
import api from "../../api";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "INTERMEDIAIRE",
    selector: (row) => row.nom_int,
    sortable: true,
  },
  {
    name: "COMMANDE",
    selector: (row) => (
      <Link to={"/view-order?IdOrders=" + row.ordr_id}># {row.ordr_id}</Link>
    ),
    sortable: true,
  },
  {
    name: "MONTANT TOTAL",
    selector: (row) => row.montant_total + " FCFA",
    sortable: true,
  },
  {
    name: "COMMISSION",
    selector: (row) => row.latefees + " FCFA",
    sortable: true,
  },
  {
    name: "STATUT",
    selector: (row) => (
      <Badge bg={row.color}>
        <span className="text-lowercase">{row.statut_pay}</span>
      </Badge>
    ),
    sortable: true,
  },
  {
    name: "DATE",
    selector: (row) => dateFormat(row.date_pay, "d mmm yyyy"),
    sortable: true,
  },
];
const paginationComponentOptions = {
  rowsPerPageText: "Lignes par page",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Tous",
};
function PayementList(props) {
  const [pay, setPay] = useState([]);
  const [pending, setPending] = useState(true);
  const fetctPay = () => {
    api
      .get(`Paiements/gettAllPayment`)
      .then((res) => {
        const pay = res.data.result.paiements;
        console.log(pay);
        const timeout = setTimeout(() => {
          setPay(pay);
          setPending(false);
        }, 1000);
        return () => clearTimeout(timeout);
      });
  };
  useEffect(() => {
    fetctPay();
  }, []);

  return (
    <Fragment>
      <DataTable
        columns={columns}
        data={pay}
        progressPending={pending}
        // progressComponent={<CustomLoader />}
        selectableRows
        pagination
        paginationComponentOptions={paginationComponentOptions}
      />
    </Fragment>
  );
}

export default PayementList;
