import React, { Fragment } from 'react';

function Navbar(props) {
    return (
        <Fragment>
                  <nav className="main-header navbar navbar-expand navbar-white navbar-light elevation-3">
          {/* <!-- Left navbar links --> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
              <i class="far fa-user-circle"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="dropdown-divider"></div>
                
                <div className="dropdown-divider"></div>
               
                <div className="dropdown-divider"></div>
               
                <div className="dropdown-divider"></div>
                
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="fullscreen"
                href="#"
                role="button"
              >
                <i className="fas fa-expand-arrows-alt"></i>
              </a>
            </li>
        
          </ul>
        </nav>
        </Fragment>
    );
}

export default Navbar;